import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "한국 사회의 공익을 높이려면?",
  "slug": "data4",
  "date": "2022-02-28T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb04.jpg",
  "featuredImg": "../images/featured/featured04.jpg",
  "summary": "공익에 대한 여러 쟁점을 중심으로 국민이 함께 행복해지는 방안에 대한 의견 을 모아보았다.",
  "tags": ["정책", "공익", "공공성"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`공익이나 공공성'이라는 단어를 흔히 들을 수 있지만, 정확한 의미가 모호하게 느껴진다. 그도 그럴 것이 공익에 대해서는 학자마다 정의와 접근법이 다르고, 시대에 따라 그 의미가 변해왔다. 또한 매우 광범위하게 쓰이기 때문에 한정적인 정의로는 의미를 파악하기 어렵다. 그때그때의 맥락에 따라 이해하는 수밖에 없다.`}<br parentName="p"></br>{`
`}{`사전적으로 말하자면 공익은 공공의 이익, 즉 사회 구성원 전체의 이익을 말한다. 하지만 공익이 사익과 대립하는 개념은 아니다. 사익을 억제해야 공익이 커지는 것은 아니라는 말이다. 개인이나 여러 사회 집단은 서로 부딪치거나 협력하여 가치 체계를 만들어내는데, 이것이 다수 이익에 최대한 일치하는 쪽으로 향한다. 사익의 단순 합계가 아니라, 사익과 구별되는 실재적 개념으로서 공공성을 띤 이익이 존재한다.`}<br parentName="p"></br>{`
`}{`한국 사회에서 사역과 구별되는 공익이란 무엇인가? 그것을 어떻게 추구할 수 있는가? 공익에 대한 여러 쟁점을 중심으로 국민이 함께 행복해지는 방안에 대한 의견 을 모아보았다.`}</p>
    <h2>{`시민 사회와 기업이 공익에 더 효과적으로 기여하는 방식은 무엇이라고 생각하십니까?`}</h2>

    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <h3>{`시민 사회와 기업의 효과적인 공익 기여 방식`}</h3>
    <p>{`소외 계층 직접 지원은 40대 이하와 서울에서 전체 평균보다 높았으며, 복지 시설 위탁 운영은 60대 이상과 대전·충청, 광주·전라 응답자에서 1순위로 꼽혔다.`}</p>
    <h3>{`사교육을 법으로 규제해야 하나?`}</h3>
    <p>{`<자녀의 사교육비 부담 때문에 많은 국민이 노후 대비도 못한 채 고통을 겪고 있습니다. 이를 해결하기 위해 헌법을 개정해서라도 ‘사교육을 규제’하자는 의견에 대해 어떻게 생각하십니까?>라는 질문에 찬성 59.0%, 반대 30.0%, 잘 모른다 11.0%가 나왔다.
찬성 의견은 40대와 50대에서 전체 평균보다 높았으며, 반대 의견은 20대와 학생이 찬성보다 높은 비율을 보였다.`}</p>
    <h3>{`청년 실업 해결 노력 누가 해야 하나?`}</h3>
    <p>{`<청년 실업률이 심각하다고 합니다. 누구의 노력이 더 필요하다고 보십니까?>라는 질문는 국가가 노력 58.0%, 기업이 노력 33.7%, 잘 모른다 8.3%가 나왔다.
국가가 더 노력해야 한다는 의견이 전반적으로 높은 가운데 20대와 진보 성향의 응답자가 전체 평균보다 더 많이 찬성했다. 보수 성향의 응답자들은 기업이 더 노력해야 한다는 의견이 더 많았다.`}</p>
    <h6>{`*공공의창이 기획하고 여론조사 전문기관 우리리서치가 수행 했다. 2016년 10월 12~13일 전국 성인남녀 1,000명을 대상으로 유무선 전화 RDD 방식으로 조사했다. 95% 신뢰수준에서 허용오차 ±3.1%p다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      